import axios from "axios";

/**
 * Fetches the list of administrators from the API.
 *
 * @return {Array} The response data from the API containing the list of admin.
 */
export const fetchHomePageContent = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_ADMIN_API}/content/retrieve/homepage`,
      { withCredentials: true }
    );

    // console.log(res.data);

    // const parsedContent = JSON.parse(res.data.content);

    return res.data.content;
  } catch (err) {
    const error = new Error(err.response.data.error);
    error.status = err.status;
    console.log(error);
    throw error;
  }
};
