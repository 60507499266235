import "./App.scss";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import RootLayout from "./components/UI/Layout/RootLayout";
import Home from "./pages/ContentManagement/Home";
import AboutUsPage from "./pages/ContentManagement/AboutUs";
import FaqPage from "./pages/ContentManagement/Faq";
import TermsAgreementPage from "./pages/ContentManagement/TermsAgreement";
import AccessToAccountsPage from "./pages/PromoterAccountsManagement/AccessToAccounts";
import SendNotificationsPage from "./pages/PromoterAccountsManagement/SendNotifications";
import PromoterApprovalPage from "./pages/PromoterApproval/PromoterApproval";
import MarketingMaterialManagementPage from "./pages/MarketingMaterialManagement/MarketingMaterialManagement";
import StripeAccountPage from "./pages/SystemSetting/StripeAccount";
import VenueStaffManagementPage from "./pages/SystemSetting/VenueStaffManagement";
import StaffManagementPage from "./pages/SystemSetting/StaffManagement";
import ReportsPage from "./pages/Reports/Reports";
import GeneralFaqPage from "./pages/GeneralFaq/GeneralFaq";
import LoginPage, { action as loginAction } from "./pages/Login/Login";
import ErrorPage from "./pages/Error";
import AuthContextProvider from "./store/auth-context";
import OAuthHandler, {
  loader as googleAuthLoader,
} from "./pages/Login/OAuthHandler";
import ResetPasswordPage, {
  action as resetPasswordAction,
} from "./pages/ResetPassword/ResetPassword";
import TextFormatterPage from "./pages/TextFormatter";
import InvitationHandler, {
  loader as invitationLoader,
} from "./pages/Login/InvitationHandler";
import { queryClient } from "./utils/https";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },

      {
        path: "content-management",
        children: [
          { path: "home", element: <Home /> },
          { path: "about-us", element: <AboutUsPage /> },
          { path: "faq", element: <FaqPage /> },
          { path: "terms-and-agreement", element: <TermsAgreementPage /> },
        ],
      },
      {
        path: "promoter-accounts-management",
        children: [
          { path: "access-to-accounts", element: <AccessToAccountsPage /> },
          { path: "send-notifications", element: <SendNotificationsPage /> },
        ],
      },
      {
        path: "promoter-approval",
        element: <PromoterApprovalPage />,
      },
      { path: "reports", element: <ReportsPage /> },
      {
        path: "system-setting",
        children: [
          {
            path: "staff-management",
            element: <StaffManagementPage />,
          },
          {
            path: "venue-staff-management",
            element: <VenueStaffManagementPage />,
          },
          { path: "stripe-account", element: <StripeAccountPage /> },
        ],
      },
      {
        path: "marketing-material-management",
        element: <MarketingMaterialManagementPage />,
      },
      {
        path: "faq",
        element: <GeneralFaqPage />,
      },
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
    errorElement: <ErrorPage />,
    action: loginAction,
  },
  {
    path: "/reset-password",
    errorElement: <ErrorPage />,
    element: <ResetPasswordPage />,
    action: resetPasswordAction,
  },
  {
    path: "/oauth2/redirect",
    errorElement: <ErrorPage />,
    element: <OAuthHandler />,
    loader: googleAuthLoader,
  },
  {
    path: "/callback",
    errorElement: <ErrorPage />,
    element: <InvitationHandler />,
    loader: invitationLoader,
  },
]);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <RouterProvider router={router} />
      </AuthContextProvider>
    </QueryClientProvider>
  );
}

export default App;
