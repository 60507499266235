import axios from "axios";
import { dataURLToFile } from "../../../file-conversion";

export const updateHomePage = async (pageContent) => {
  const file = dataURLToFile(pageContent.heroImg, "image.jpg");
  const isNewImageAdded = file instanceof File;

  // console.log(file);

  // const isNewImageAdded = pageContent.heroImg instanceof File;
  let imageId;
  if (isNewImageAdded) {
    try {
      const requireSignedURLs = false;
      const imageData = new FormData();
      imageData.append("file", file); // Add the file
      imageData.append("metadata", JSON.stringify({ key: "value" }));
      imageData.append("requireSignedURLs", requireSignedURLs.toString());

      const res = await axios.post(
        `${process.env.REACT_APP_ADMIN_API}/image-upload`,
        imageData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      imageId = res.data.imageId;

      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  }

  try {
    console.log(isNewImageAdded, imageId);
    const updatedContent = isNewImageAdded
      ? { ...pageContent, heroImg: imageId }
      : pageContent;

    console.log(updatedContent);
    const res = await axios.post(
      `${process.env.REACT_APP_ADMIN_API}/content/update/homepage`,
      updatedContent,
      {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      }
    );

    return res.data;
  } catch (err) {
    console.log(err);
    const error = new Error(err.response.data.error);
    error.status = err.status;
    console.log(error);
    throw error;
  }
};
