import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { updateAdminRoles } from "../../../utils/https/StaffManagement/mutation";
import { queryClient } from "../../../utils/https";

import AdminItem from "./AdminItem";
import ActionButtons from "../../UI/Buttons/ActionButtons";

import styles from "./AdminList.module.scss";

function AdminList({ list }) {
  const [adminList, setAdminList] = useState([]);
  const [notification, setNotification] = useState(null);

  const { mutate, isPending, isError, error, data } = useMutation({
    mutationFn: updateAdminRoles,
    onMutate: async () => {
      await queryClient.cancelQueries(["adminList"]);
      const previousList = queryClient.getQueryData(["adminList"]);
      queryClient.setQueryData(["adminList"], () => adminList);
      return { previousList };
    },
    onSettled: () => {
      queryClient.invalidateQueries(["adminList"]);
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(["adminList"], context.previousList);
    },
  });

  useEffect(() => {
    if (list) {
      setAdminList(structuredClone(list));
    }
  }, [list]);

  useEffect(() => {
    if (data) {
      setNotification(data.message);
      const timer = setTimeout(() => setNotification(null), 3000);
      return () => clearTimeout(timer);
    }
  }, [data]);

  /**
   * Handles the submission of updated admin roles.
   * Filters the admin list to find admins with changed roles and sends the updated data to the API.
   *
   * @return {void}
   */
  const submitHandler = () => {
    mutate(updatedAdmins);
  };

  /**
   *  Handles the change of role for an admin in the admin list.
   *
   *  @param {string} workosId - The WorkOS id of the admin to update.
   *  @param {number} newRole - The role id of the new role to assign to the admin.
   *  @return {void}
   */
  const roleChangeHandler = (workosId, newRole) => {
    const updatedAdminList = [...adminList];
    const updatedAdminIndex = updatedAdminList.findIndex(
      (admin) => admin.workosId === workosId
    );
    updatedAdminList[updatedAdminIndex].role = newRole;
    setAdminList(updatedAdminList);
  };

  // Check which admin's role has changed
  const updatedAdmins = adminList.filter((admin) =>
    list.some(
      (prev) => prev.workosId === admin.workosId && prev.role !== admin.role
    )
  );

  const actionButtons = [
    {
      text: "Update",
      variant: "primary",
      onClick: submitHandler,
      disabled: isPending || updatedAdmins.length === 0,
      className: styles["action-button"],
    },
  ];

  return (
    <div className={styles["admin-list-container"]}>
      <div className={styles["admin-list"]}>
        <h3 className={styles.title}>Admin List</h3>
        {isError && <p>{error.message}</p>}
        {notification}
        <ul>
          {adminList?.map((admin) => (
            <AdminItem
              key={admin.workosId}
              admin={admin}
              isPending={isPending}
              onChangeRole={roleChangeHandler}
            />
          ))}
        </ul>
      </div>
      <ActionButtons
        buttons={actionButtons}
        className={styles["action-buttons-flex-end"]}
      />
    </div>
  );
}

export default AdminList;
