import { useState } from "react";
import Cropper from "react-easy-crop";

import styles from "./ImageCropper.module.scss";
import { MdZoomIn, MdZoomOut } from "react-icons/md";
import { TfiHandDrag } from "react-icons/tfi";
import ActionButtons from "../Buttons/ActionButtons";
import Slider from "../Slider";

export default function ImageCropper({
  image,
  aspectRatio,
  onCropDone,
  onCropCancel,
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(2);
  const [croppedArea, setCroppedArea] = useState(null);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const actionButtons = [
    {
      text: "Change Photo",
      variant: "secondary",
      onClick: onCropCancel,
    },
    {
      text: "Apply",
      variant: "primary",
      onClick: () => {
        onCropDone(croppedArea);
      },
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles["cropper-container"]}>
        <div className={styles.cropper}>
          <Cropper
            image={image}
            aspect={aspectRatio}
            crop={crop}
            zoom={zoom}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            style={{
              containerStyle: {
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
              },
              cropAreaStyle: { border: "3px solid #27aae1" },
            }}
          />
        </div>
        <div className={styles.controls}>
          <div className={styles["zoom-slider"]}>
            <MdZoomOut size={24} />
            <Slider
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              onChange={(e) => setZoom(e.target.value)}
            />
            <MdZoomIn size={24} />
          </div>
          <div className={styles["drag-tips"]}>
            <TfiHandDrag size={24} />
            <span>Drag the image select the area you want to crop.</span>
          </div>
        </div>
      </div>

      <ActionButtons
        buttons={actionButtons}
        className={styles["action-buttons-flex-end"]}
      />
    </div>
  );
}
