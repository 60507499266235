import { useState } from "react";

export default function useImageCropper() {
  const [originalImg, setOriginalImg] = useState("");

  /**
   * Handles the image cropping process when the user is done cropping using react-easy-crop.
   *
   * @param {object} imgCroppedArea - The cropped area of the image.
   * @param {function} updateFn - A callback function to provide the cropped image URL.
   * @param {function} closeModalFn - A callback function to close the modal.
   */
  const onCropDone = (imgCroppedArea, updateFn, closeModalFn) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;
    canvasEle.height = imgCroppedArea.height;

    const context = canvasEle.getContext("2d");

    let imageObj = new Image();
    imageObj.src = originalImg;
    console.log(imgCroppedArea);
    imageObj.onload = async function () {
      context.drawImage(
        imageObj,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );

      const dataURL = canvasEle.toDataURL("image/jpeg");

      closeModalFn();
      updateFn(dataURL);
    };
  };

  const onCropCancel = (closeModalFn) => {
    setOriginalImg("");
    closeModalFn();
  };

  return {
    originalImg,
    setOriginalImg,
    onCropDone,
    onCropCancel,
  };
}
