import { useState } from "react";

import Button from "../UI/Buttons/Button";
import ReportFilterForm from "./ReportFilterForm";
import ModalWrapper from "../UI/Layout/ModalWrapper";

import styles from "./ReportList.module.scss";
import { Modal } from "react-bootstrap";
import { IoFilterCircleSharp } from "react-icons/io5";

export default function ReportList({
  optionList,
  selectedOptions,
  selectedFilters,
  isSaleTimeAllDay,
  onToggleOption,
  onAddSearchFilter,
  onRemoveSearchFilter,
  onSelectCategory,
  onSelectDateRange,
  onSelectSaleTime,
  onResetFilters,
  onToggleSaleTimeAllDay,
}) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <form className={styles["option-list"]}>
        <div className={styles["option-item"]}>
          <Button
            className={styles["filter-button"]}
            onClick={() => setShowModal(true)}
          >
            <IoFilterCircleSharp size={24} />
            <span>Filters</span>
          </Button>
        </div>
        {optionList.map((option) => (
          <div key={option.oid} className={styles["option-item"]}>
            <input
              type="checkbox"
              id={`report-${option.oid}`}
              className={styles["option-checkbox"]}
              checked={selectedOptions.includes(option.oid)}
              onChange={() => onToggleOption(option.oid)}
            />
            <div className={styles["option-label"]}>
              <label htmlFor={`report-${option.oid}`}>{option.title}</label>

              {option.description && (
                <p className={styles["option-description"]}>
                  {option.description}
                </p>
              )}
            </div>
          </div>
        ))}
      </form>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
      >
        <ModalWrapper onClose={() => setShowModal(false)}>
          <ReportFilterForm
            selectedFilters={selectedFilters}
            isSaleTimeAllDay={isSaleTimeAllDay}
            onAddSearchFilter={onAddSearchFilter}
            onRemoveSearchFilter={onRemoveSearchFilter}
            onSelectCategory={onSelectCategory}
            onSelectDateRange={onSelectDateRange}
            onSelectSaleTime={onSelectSaleTime}
            onResetFilters={onResetFilters}
            onToggleSaleTimeAllDay={onToggleSaleTimeAllDay}
            onSubmit={() => setShowModal(false)}
          />
        </ModalWrapper>
      </Modal>
    </>
  );
}
