import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import styles from "./NavGroup.module.scss";
import { FaAngleDown } from "react-icons/fa";

export default function NavGroup({ title, links, path, onClose }) {
  const [isActive, setIsActive] = useState(false);

  const { pathname } = useLocation();

  return (
    <div className={styles["accordion-item"]}>
      <div
        className={`${
          pathname.startsWith(`/${path}`) && isActive
            ? styles["active-section"]
            : ""
        } ${styles["accordion-title"]}`}
        onClick={() => setIsActive((prev) => !prev)}
      >
        <p>{title}</p>
        <FaAngleDown
          className={`${styles["accordion-arrow"]} ${
            isActive ? styles["rotate-up"] : ""
          }`}
        />
      </div>
      {isActive && (
        <div
          className={styles["accordion-content"]}
          style={{
            backgroundColor: pathname.startsWith(`/${path}`)
              ? "#9dccdf"
              : "transparent",
          }}
        >
          {links.map((link) => (
            <NavLink
              key={link.name}
              to={link.path}
              className={({ isActive }) => (isActive ? styles.active : "")}
              onClick={onClose}
            >
              {link.name}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
}
