import { DUMMY_REQUESTS_LIST } from "../../DUMMY_DATA/DUMMY_REQUEST";
import ActionButtons from "../UI/Buttons/ActionButtons";

import styles from "./RequestList.module.scss";
import { Table } from "react-bootstrap";

const actionButtons = {
  toIndividuals: [
    {
      text: "Approve",
      variant: "primary",
    },
    {
      text: "Reject",
      variant: "secondary",
    },
  ],
  toAll: [
    {
      text: "Approve All",
      variant: "primary",
    },
    {
      text: "Reject All",
      variant: "secondary",
    },
  ],
};

function RequestList({ searchTerm }) {
  let requestList = DUMMY_REQUESTS_LIST;

  if (searchTerm) {
    requestList = DUMMY_REQUESTS_LIST.filter((request) =>
      request.promoterName
        .toLowerCase()
        .includes(searchTerm.trim().toLowerCase())
    );
  }

  return (
    <Table responsive className={styles["request-list"]}>
      <thead>
        <tr>
          <th>Request ID</th>
          <th>Request Date</th>
          <th>User Name</th>
          <th>Status</th>
          <th>Action</th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
        {requestList.map((request) => (
          <tr key={request.rid}>
            <td>{request.rid}</td>
            <td>{request.requestDate}</td>
            <td>{request.promoterName}</td>
            <td>
              <div className={styles.status}>
                {request.status === "Approved" && (
                  <div style={{ backgroundColor: "green" }}></div>
                )}
                {request.status === "Pending" && (
                  <div style={{ backgroundColor: "orange" }}></div>
                )}
                {request.status === "Rejected" && (
                  <div style={{ backgroundColor: "red" }}></div>
                )}
                <span>{request.status}</span>
              </div>
            </td>
            <td>
              {request.status === "Pending" && (
                <ActionButtons
                  buttons={actionButtons.toIndividuals}
                  className={styles["action-buttons-flex-start"]}
                />
              )}
            </td>
            <td>
              <button className={styles["view-details-button"]}>
                View Details
              </button>
            </td>
          </tr>
        ))}
        {!searchTerm && requestList.length !== 0 && (
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td colSpan={2}>
              <ActionButtons
                buttons={actionButtons.toAll}
                className={styles["action-buttons-flex-start"]}
              />
            </td>
          </tr>
        )}
        {requestList.length === 0 && (
          <tr>
            <td colSpan={6}>No requests found.</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default RequestList;
