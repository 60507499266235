import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import styles from "./ImageUploader.module.scss";
import Button from "../Buttons/Button";
import { IoCloudUploadOutline } from "react-icons/io5";

export default function ImageUploader({
  id,
  image,
  onAddImages,
  className,
  style,
}) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        onAddImages(e.target.result);
      };

      reader.readAsDataURL(file);
    },
    [onAddImages]
  );

  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    },
  });

  const buttonClickHandler = (e) => {
    e.stopPropagation();
    document.getElementById(id).click();
  };

  const fileChangeHandler = useCallback(
    (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        onAddImages(e.target.result);
      };

      reader.readAsDataURL(file);
    },
    [onAddImages]
  );

  return (
    <div
      {...getRootProps()}
      className={`${className} ${styles["image-container"]} ${
        isDragActive ? styles["drag-active"] : ""
      }`}
      style={{ ...style, filter: isDragActive ? "grayscale(0.8)" : "" }}
      onClick={(e) => e.stopPropagation()}
    >
      {/* <input {...getInputProps()} /> */}
      <div className={`${styles.content} ${image ? styles["has-image"] : ""}`}>
        <IoCloudUploadOutline size={96} />
        <p className={styles["content-text"]}>
          {isDragActive ? "Drop it!" : "Drag and drop an image or"}
        </p>
        {!isDragActive && (
          <>
            <Button
              className={styles["change-image-button"]}
              onClick={buttonClickHandler}
            >
              Upload Image
            </Button>
            <label htmlFor={id} style={{ display: "none" }}>
              Image Upload
            </label>
            <input
              id={id}
              type="file"
              accept="image/*"
              onClick={(e) => e.stopPropagation()}
              onChange={fileChangeHandler}
              style={{ display: "none" }}
            />
          </>
        )}
      </div>
    </div>
  );
}
