export const DUMMY_CM_HOME = {
  heroImg:
    "https://cdn.builder.io/api/v1/image/assets/TEMP/edcfe89945695d0f2636d9f57a17704b86a864c18f9e34a8661b0eaa6fc9065e?placeholderIfAbsent=true&apiKey=d92a299f0cfc4f17bca506c7d54a7408",
  lang: {
    en: {
      cta: {
        events: {
          subtitle: "Discover a live event or stream",
          description: "Fans | Audiences | Spectators",
          cta: "Get Started Now!",
        },
        promoters: {
          subtitle: "Start selling tickets now.",
          description: "Promoters | Venues | Event Planners",
          cta: "Get Started Now!",
        },
      },
      icons: {
        link: {
          subtitle: "(Promote an event)",
          description:
            "Create your event in minutes and start linking to your audience",
        },
        share: {
          subtitle: "(Be an Influencer)",
          description:
            "Discounts, Prizes & Rewards\nPromote on social media share in the experience",
        },
        connect: {
          subtitle: "(Stay in the loop)",
          description:
            "Create the experience and connect with fans while building your audience",
        },
      },
      quote: "Experience a live event the way it should be",
    },
  },
};

export const DUMMY_CM_ABOUTUS = {
  aboutUs: {
    title: "About Us",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse quis tristique turpis. Phasellus rhoncus erat velit, ut pellentesque nisl facilisis a. Suspendisse et blandit arcu, nec feugiat elit. Nullam placerat ante sit amet mauris scelerisque, ac pharetra est consequat. Nullam et nulla ex. Proin ac ante lacinia, accumsan odio ac, fermentum turpis. Suspendisse potenti. Etiam tempor magna sed finibus congue. Sed viverra est vitae magna accumsan, a hendrerit ex luctus. Vivamus volutpat nunc sit amet mauris posuere interdum. Maecenas vel metus ante. Suspendisse potenti. Sed vitae luctus quam. Etiam maximus ut arcu vel pellentesque. Nam vulputate nisi eros, sit amet semper eros fermentum a.",
  },
  testimonial: {
    title: "Testimonial",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse quis tristique turpis. Phasellus rhoncus erat velit, ut pellentesque nisl facilisis a. Suspendisse et blandit arcu, nec feugiat elit. Nullam placerat ante sit amet mauris scelerisque, ac pharetra est consequat. Nullam et nulla ex. Proin ac ante lacinia, accumsan odio ac, fermentum turpis. Suspendisse potenti. Etiam tempor magna sed finibus congue. Sed viverra est vitae magna accumsan, a hendrerit ex luctus. Vivamus volutpat nunc sit amet mauris posuere interdum. Maecenas vel metus ante. Suspendisse potenti. Sed vitae luctus quam. Etiam maximus ut arcu vel pellentesque. Nam vulputate nisi eros, sit amet semper eros fermentum a.",
  },
};

export const DUMMY_CM_TERMS = {
  termsOfUse:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse quis tristique turpis. Phasellus rhoncus erat velit, ut pellentesque nisl facilisis a. Suspendisse et blandit arcu, nec feugiat elit. Nullam placerat ante sit amet mauris scelerisque, ac pharetra est consequat. Nullam et nulla ex. Proin ac ante lacinia, accumsan odio ac, fermentum turpis. Suspendisse potenti. Etiam tempor magna sed finibus congue. Sed viverra est vitae magna accumsan, a hendrerit ex luctus. Vivamus volutpat nunc sit amet mauris posuere interdum. Maecenas vel metus ante. Suspendisse potenti. Sed vitae luctus quam. Etiam maximus ut arcu vel pellentesque. Nam vulputate nisi eros, sit amet semper eros fermentum a.",
  privacyAgreement:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse quis tristique turpis. Phasellus rhoncus erat velit, ut pellentesque nisl facilisis a. Suspendisse et blandit arcu, nec feugiat elit. Nullam placerat ante sit amet mauris scelerisque, ac pharetra est consequat. Nullam et nulla ex. Proin ac ante lacinia, accumsan odio ac, fermentum turpis. Suspendisse potenti. Etiam tempor magna sed finibus congue. Sed viverra est vitae magna accumsan, a hendrerit ex luctus. Vivamus volutpat nunc sit amet mauris posuere interdum. Maecenas vel metus ante. Suspendisse potenti. Sed vitae luctus quam. Etiam maximus ut arcu vel pellentesque. Nam vulputate nisi eros, sit amet semper eros fermentum a.",
};

export const DUMMY_CM_FAQ = [
  {
    qid: 1,
    question: "Is there a fee for using EventLinx?",
    answer:
      "EventLinx is an innovative online platform designed to make event management and ticketing easy and accessible for everyone. Whether you're organizing a small local gathering or a large-scale concert, our platform allows you to create, promote, and manage events seamlessly. Attendees can easily browse upcoming events, purchase tickets, and manage their bookings all in one place.",
  },
  {
    qid: 2,
    question: "How do I create an account?",
    answer:
      "EventLinx is an innovative online platform designed to make event management and ticketing easy and accessible for everyone. Whether you're organizing a small local gathering or a large-scale concert, our platform allows you to create, promote, and manage events seamlessly. Attendees can easily browse upcoming events, purchase tickets, and manage their bookings all in one place.",
  },
  {
    qid: 3,
    question: "How do I create an event?",
    answer:
      "EventLinx is an innovative online platform designed to make event management and ticketing easy and accessible for everyone. Whether you're organizing a small local gathering or a large-scale concert, our platform allows you to create, promote, and manage events seamlessly. Attendees can easily browse upcoming events, purchase tickets, and manage their bookings all in one place.",
  },
  {
    qid: 4,
    question: "Can I edit my event after it's been published?",
    answer:
      "EventLinx is an innovative online platform designed to make event management and ticketing easy and accessible for everyone. Whether you're organizing a small local gathering or a large-scale concert, our platform allows you to create, promote, and manage events seamlessly. Attendees can easily browse upcoming events, purchase tickets, and manage their bookings all in one place.",
  },
  {
    qid: 5,
    question: "Can I get a refund if I can't attend an event?",
    answer:
      "EventLinx is an innovative online platform designed to make event management and ticketing easy and accessible for everyone. Whether you're organizing a small local gathering or a large-scale concert, our platform allows you to create, promote, and manage events seamlessly. Attendees can easily browse upcoming events, purchase tickets, and manage their bookings all in one place.",
  },
];
