import styles from "./ModalHeader.module.scss";

export default function ModalHeader({ title, description }) {
  return (
    <div className={styles["modal-header"]}>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );
}
