export const dataURLToFile = (dataUrl, fileName) => {
  // Split the data URL into components
  const arr = dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1]; // Extract the MIME type
  const bstr = atob(arr[1]); // Decode the base64 string
  const n = bstr.length;
  const u8arr = new Uint8Array(n); // Create a typed array

  // Populate the typed array with binary data
  for (let i = 0; i < n; i++) {
    u8arr[i] = bstr.charCodeAt(i);
  }

  // Create a Blob from the typed array
  const blob = new Blob([u8arr], { type: mime });

  // Create a File from the Blob
  return new File([blob], fileName, { type: mime });
};
