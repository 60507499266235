import { useState } from "react";
import useImageCropper from "../../../hooks/useImageCropper";

import SectionWrapper from "../../UI/Layout/SectionWrapper";
import ImageUploader from "../../UI/Image/ImageUploader";
import ModalHeader from "../../UI/Layout/ModalHeader";
import ImageCropper from "../../UI/Image/ImageCropper";

import styles from "./HeroImage.module.scss";
import { Modal } from "react-bootstrap";

function HeroImage({ image, onUpdate }) {
  const [showCropModal, setShowCropModal] = useState(false);

  const { originalImg, setOriginalImg, onCropDone, onCropCancel } =
    useImageCropper();

  const addImagesHandler = (dataUrl) => {
    setShowCropModal(true);
    setOriginalImg(dataUrl);
  };

  return (
    <SectionWrapper
      title="Hero Image"
      description="You can change the hero image here"
    >
      <ImageUploader
        id="hero-image"
        image={image}
        onAddImages={addImagesHandler}
        className={styles["hero-image-container"]}
        style={{
          backgroundImage: image.startsWith("data:image")
            ? `url(${image})`
            : `url("${process.env.REACT_APP_IMAGE_RETRIEVE_URL}/${image}/public")`,
        }}
      />

      <Modal
        show={showCropModal}
        onHide={() => setShowCropModal(false)}
        size="lg"
        centered
      >
        <ModalHeader title="Hero Banner" />
        <ImageCropper
          image={originalImg}
          aspectRatio={21 / 9}
          onCropDone={(imgCroppedArea) =>
            onCropDone(
              imgCroppedArea,
              (url) =>
                onUpdate((prev) => {
                  if (prev.heroImg === url) {
                    return prev;
                  }
                  return {
                    ...prev,
                    heroImg: url,
                  };
                }),
              () => setShowCropModal(false)
            )
          }
          onCropCancel={() => onCropCancel(() => setShowCropModal(false))}
        />
      </Modal>
    </SectionWrapper>
  );
}

export default HeroImage;
